import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import "./bluestatement.scss"


export default class BlueStatement extends Component {

  static propTypes = {
    statement: PropTypes.string,
    footnote: PropTypes.string
  }

  static defaultProps = {
    statement: '',
    footnote: ''
  }

  componentDidMount() {
    this.blueStatement = React.createRef();
  }

  render() {

    return (
      <div className="blue-statement-wrapper" ref={this.blueStatement}>
        <div className="block-container blue-statement-container" >
          <div className="block-container-inner">
            <div className="blue-statement-content">  
              <p>{this.props.statement}</p>
              <div className="statement-footnote">{this.props.footnote}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
