import React, { Component } from 'react'

import Link from "../components/shared/customlink.js"

import Layout from "../components/layout/layout"
import SectionContainer from "../components/layout/sectioncontainer"

import Hero from "../components/shared/hero"

import GridPane from "../components/grid/gridpane"
import GridImage from "../components/grid/gridimage"
import GridContentBlock from "../components/grid/gridcontentblock"

import AnimHeader from "../components/shared/animheader"
import SEO from "../components/seo"
import ConnectBlock from "../components/sections/connectblock"

import BlueStatement from "../components/sections/bluestatement"

import Iframe from 'react-iframe'
import VisibilitySensor from 'react-visibility-sensor'

import { Place04 } from '../components/images/place-04';
import { Place05 } from '../components/images/place-05';
import { Place06 } from '../components/images/place-06';
import { Place07 } from '../components/images/place-07';
import { Place11 } from '../components/images/place-11';
import { Place12 } from '../components/images/place-12';
import { Place13 } from '../components/images/place-13';
import { Place14 } from '../components/images/place-14';
import { Place17 } from '../components/images/place-17';
import { Place18 } from '../components/images/place-18';
import { Place19 } from '../components/images/place-19';
import { PlacePanoPoster } from '../components/images/place-pano-poster';

import { TimelineMax, TweenMax, TweenLite, Linear, Power4 } from 'gsap';

export default class WorkPlacePage extends Component {

  constructor({props, location}) {
    super(props);

    this.locationState = location.state;
    this.layout = React.createRef();
    this.panoPoster = React.createRef();

    this.state = {
      'signalAnimComplete': false,
      'panoEnabled': false
    }
  }

  enablePano = () => {
    this.setState({'panoEnabled': true});

    let tl = new TimelineMax();

    tl.to(this.panoPoster.current, 2.4, { opacity: 0 });
    tl.set(this.panoPoster.current, { display: 'none' });
    tl.play();  
  }

  componentDidMount() {
    this.toggleGallery = this.layout.current.toggleGallery;
    this.navSwitchCallback = this.layout.current.navSwitchCallback;
    this.progressCheckCallback = this.layout.current.progressCheckCallback;
    this.layout.current.runSignalAnim(() => { this.setState({'signalAnimComplete': true }) })
  }

  render() {
    return (
      <Layout ref={this.layout}>
        <SEO title="Work/Place Experience" description="Discover a next-gen building that enhances community through connectivity and productivity by design. Recruiting has never been this easy." />
        
        <Hero pageClass="workplace" headerText={[`work/place`,`experience`]}
            descText="Discover a next-gen building that enhances community 
            through connectivity and productivity by design. Recruiting has never been this&nbsp;easy." 
            locationState={this.locationState}
            eyeBrowText="THE BUILDING"
            navSwitchCallback={this.navSwitchCallback}
            progressCheckCallback={this.progressCheckCallback}  />

          {/* SECTION 1 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="first-section" section="workplace" dashIncrement="20" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`most experience`,`per square foot`,`in the bay`]} descText={`875,000 SF of next-gen workspace in a connected vertical community. Telegraph Tower offers more than the competition to support your company’s culture and productivity.`} descAlign="right" buttonText={`See Leasing details`} buttonLink={`/leasing#leasing-section-2`} buttonClass={`solid-bckg`} />
          </SectionContainer>

          {/* SECTION 2 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section2" dashIncrement="30" inverted={false} navSwitchCallback={this.navSwitchCallback}>
          <AnimHeader typeText={[`designed for`,`well-being`]} descText={`Telegraph Tower is  in tune with the outdoors. Its wellness-first design creates an environment that boosts moods, creativity and productivity.`} descAlign="right"/>
            <GridPane type="grid1">
              
              <GridImage position="mid-left" clickable="clickable" clickHandler={() => this.toggleGallery('render-02')}>
                <Place05 />
              </GridImage>

              <GridImage position="top-right" clickable="clickable" clickHandler={() => this.toggleGallery('render-03')}>
                <Place06 />
              </GridImage>
              
              <GridImage position="bot-right" textContent={true} gradientType="yellowblue">
                <GridContentBlock contentType="list" content={[`+/- 30k square feet of outdoor space`,`33 balconies`,`Three outdoor terraces with NanaWalls`,`Fitness center with showers and lockers`]}/>
              </GridImage>

            </GridPane>
            <div className="centered-content">
              <Link to='/leasing#leasing-section-specs' className='btn'>get the specs</Link>
            </div>
          </SectionContainer>

          {/* SECTION 3 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section3" dashIncrement="40" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`designed for`,`visionaries`]} descText="The virtually column-free floorplates and high ceilings create panoramic views and an abundance of natural light." descAlign="right"/>
            <GridPane type="grid3">

              <GridImage position="top-left">
                {/*<Place03 />*/}

                {this.state.panoEnabled && 
                <Iframe url="https://telegraph-tower-assets.s3-us-west-2.amazonaws.com/panos/pano-sky/index.html "
                  width="100%"
                  height="100%"
                  id="myId"
                  className="iframeHero"
                  display="initial"
                  position="relative"/>}

                <div ref={this.panoPoster} onClick={this.enablePano} style={{ cursor: `pointer`, width: `100%`, height: `100%`, background: `blue` }}>
                  <PlacePanoPoster />  
                </div>
                
              </GridImage>

              <GridImage position="bot-left" textContent={true} gradientType="blueblue">
                <GridContentBlock contentType="list" content={[`15' deck-to-deck heights`, `+/-35,000 square foot floorplates`,`11'6'' vision glass`]}/>
              </GridImage>
              
              <GridImage position="mid-right">
                <Place04 />
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 4 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section4" dashIncrement="45" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <div className="full-width-img">
              <Place07 />
            </div>
          </SectionContainer>

          {/* SECTION 5 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section5" dashIncrement="50" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <BlueStatement statement={"Exposure to natural light is shown to generate a 5–14% improvement in memory and an 18% boost in productivity. 100% outside air filtration can boost cognitive abilities up to 61% and add an 8% increase in productivity."} footnote={"sources: World Green Building Council, Harvard's Center for Health and the Global Environment"} />
          </SectionContainer>

          {/* SECTION 6 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section6" dashIncrement="60" inverted={false} navSwitchCallback={this.navSwitchCallback}>
          <AnimHeader typeText={[`smart`,`building`,`design`]} descText="Telegraph Tower features the latest in smart building design, with advanced and linked systems for: information, climate, lighting, and security—all aimed at creating value for your company and a great experience for your employees." descAlign="left"/>
            <GridPane type="grid1">
              
              <GridImage position="mid-left">
                <Place11 />
              </GridImage>

              <GridImage position="top-right" clickable="clickable" clickHandler={() => this.toggleGallery('render-05')}>
                <Place12 />
              </GridImage>
              
              <GridImage position="bot-right">
                <Place13/>
              </GridImage>

            </GridPane>
            <div className="centered-content">
              <Link to='/leasing#fact-sheets' className='btn' locationState='safety-security-tab'>get the details</Link>
            </div>
          </SectionContainer>

         {/* SECTION 7 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section7" dashIncrement="70" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <div className="full-width-img">
              <Place14 />
            </div>
          </SectionContainer>

          {/* SECTION 8 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section8" dashIncrement="80" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`celebrate`,`artists and`, `makers`]} descText="Immerse your creative workers in the Uptown Oakland arts scene. Ground floor maker/gallery spaces, performance venue, Valley Street closures, and monthly Art Murmur can create unforgettable experiences at your front door.  Inspire your employees the moment they arrive with American Steel founder and Oakland artist Karen Cusolito’s stunning steel sculpture at the lobby entrance." descAlign="right"/>
            <GridPane type="grid3">

              <GridImage position="top-left" clickable="clickable" clickHandler={() => this.toggleGallery('render-17')}>
                <Place17 />
              </GridImage>

              <GridImage position="bot-left">
                <Place18 />
              </GridImage>
              
              <GridImage position="mid-right">
                <Place19 />
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 11 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="workplace-section11" dashIncrement="90" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <ConnectBlock />
          </SectionContainer>

      </Layout>
    )
  }
}
